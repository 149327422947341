'use client';
import Image from 'next/image';
import { CurtainInView } from '../../CurtainInView';
import { FadeUpInView } from '../../FadeUpInView';
import Link from 'next/link';
import { DESCRIPTIONDATA } from './Constants';
import { useRouter } from 'next/navigation';

export const ConditionItem = ({
  title,
  delay,
  slug,
  image,
  color,
  price,
  subtitle,
  curtainOrientation,
  objectPosition = 'center',
}) => {
  const router = useRouter();
  const matchedDescription = DESCRIPTIONDATA.find(desc => desc.slug === slug);
  const handleClick = () => {
    // router.push();
  };
  return (
    <FadeUpInView className="w-full">
      <Link
        href={`/create-case/welcome?slug=${encodeURIComponent(slug)}`}
        className="block group border border-gray-200 p-3 transition-all hover:scale-105 active:scale-105"
        onClick={() => handleClick()}
      >
        <FadeUpInView>
          <div className="flex justify-between items-center pb-2">
            <div className="text-gray-800">{title}</div>
            <button className="font-medium">{price}</button>
          </div>
        </FadeUpInView>
        <CurtainInView
          orientation={curtainOrientation}
          delay={delay}
          animationDuration="0.6s"
          className="col-span-6 w-full aspect-square mb-2 md:mb-4  "
        >
          <div
            style={{ backgroundColor: color }}
            className="w-full h-full relative z-0"
          >
            <div className="grayscale-[90%] w-full h-full xborder-[3px] border-gray-800">
              <Image
                src={image}
                alt=""
                objectFit="cover"
                layout="fill"
                objectPosition={objectPosition}
                className="group-hover:scale-110 transition-all drop-shadow-[5x_5px_8px_rgba(0,0,0,0.25)]"
              />
            </div>
          </div>
        </CurtainInView>
        <>
          {matchedDescription ? (
            <div className="font-light text-gray-600 text-xs md:text-sm mb-2">
              <div>{matchedDescription.title}</div>
              <div className="text-sm">{matchedDescription.information}</div>
            </div>
          ) : (
            <div>No matching data found</div>
          )}
        </>
      </Link>
    </FadeUpInView>
  );
};
