import { ReactNode } from 'react';

export const DESCRIPTIONDATA: Array<{
  title: string;
  information: ReactNode;
  slug: string;
}> = [
  {
    slug: 'rash',
    title: 'Detta ingår',
    information: (
      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center gap-1">
          <img src="/magnifying.svg" className="size-6" />
          <span>Bedömning</span>
        </div>
        <div className="flex items-center gap-2">
          <img src="/pill.svg" className="size-5" />
          <span>Recept</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/planrepo.svg" className="size-6" />
          <span>Behandlingsförslag</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/bubble-message.svg" className="size-6" />
          <span>Extra fråga</span>
        </div>
      </div>
    ),
  },
  {
    slug: 'acne',
    title: 'Detta ingår',
    information: (
      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center gap-1">
          <img src="/magnifying.svg" className="size-6" />
          <span>Bedömning</span>
        </div>
        <div className="flex items-center gap-2">
          <img src="/pill.svg" className="size-5" />
          <span>Recept</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/planrepo.svg" className="size-6" />
          <span>Behandlingsförslag</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/bubble-message.svg" className="size-6" />
          <span>Extra fråga</span>
        </div>
      </div>
    ),
  },
  {
    slug: 'other',
    title: 'Detta ingår',
    information: (
      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center gap-1">
          <img src="/magnifying.svg" className="size-6" />
          <span>Bedömning</span>
        </div>
        <div className="flex items-center gap-2">
          <img src="/pill.svg" className="size-5" />
          <span>Recept</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/planrepo.svg" className="size-6" />
          <span>Behandlingsförslag</span>
        </div>
        <div className="flex items-center gap-1">
          <img src="/bubble-message.svg" className="size-6" />
          <span>Extra fråga</span>
        </div>
      </div>
    ),
  },
  {
    slug: 'mole',
    title: 'Detta ingår',
    information: (
      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center gap-1">
          <img src="/magnifying.svg" className="size-6" />
          <span>Bedömning</span>
        </div>
        <div className="flex items-center gap-2">
          <img src="/pill.svg" className="size-5" />
          <span>Recept</span>
        </div>
      </div>
    ),
  },
];
