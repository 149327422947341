'use client';

import 'swiper/css';
import 'swiper/css/pagination';
import './services.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Children, FC, PropsWithChildren } from 'react';

export const ServicesMobile: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="md:hidden">
      <Swiper
        centeredSlides
        spaceBetween={30}
        className="w-screen "
        slidesPerView={1.5}
        modules={[Pagination]}
        pagination
      >
        {Children.toArray(children).map((child, idx) => {
          return (
            <SwiperSlide key={idx} className="my-4">
              <div className="flex items-center justify-center max-w-xs mx-auto pb-10">
                {child}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
